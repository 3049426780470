export const infoKey = btoa('phoneInfo');
export const forgotScreen = btoa('forgot');
export const userId = btoa('userId');
export const sessionId = btoa('sessionId');









