export const environment = {
  production: false,
  API_KEY: '1234',
  // API_BASE_PATH: 'https://devapi.boudl.app/admin/',
  API_BASE_PATH: 'https://msha3lildevapi.appskeeper.in/admin/api/v1/',
  //API_BASE_PATH: 'https://boudladmindev.appskeeper.in/',
  // FINGER_PRINT: "Basic c2hvb25hOnNob29uYUAxMjM="
  s3: {
    AWS_ACCESS_KEY: '',
    AWS_SECRET_KEY: '',
    REGION: 'ap-south-1',
    BUCKET_NAME: '',
    S3_BASE_URL:'https://app-development.s3.amazonaws.com/'
  },
  firebaseConfig: {
    apiKey: "AIzaSyBZwdtka8p1RAoqOQH1r6zsJLle7hInits",
    authDomain: "msha3lil-da607.firebaseapp.com",
    databaseURL: "https://msha3lil-da607-default-rtdb.firebaseio.com",
    projectId: "msha3lil-da607",
    storageBucket: "msha3lil-da607.appspot.com",
    messagingSenderId: "1010766169160",
    appId: "1:1010766169160:web:b0802ff581cd89a63c047d",
    measurementId: "G-66TBPS824Q",
    vapidKey: "BJ3OKYQT9exxg2s_AcRz0MldUebPoTB0jnsnEfaFQ_wBQIp6F7VpD-d43yhia1-ZQP1Eak1W2COxN4PisWN_Woc"
  },
};
