import { Injectable } from '@angular/core';
import { HttpService } from '../../../services/http/http.service';
import {
  DELETE_NOTIFICATION_API,
  NOTIFICATION_API,
  NOTIFICATION_COUNT,
  PUSH_NOTIFICATION_API,
  READ_NOTIFICATION,
  RESENT_NOTIFICATION_API,
  SEND_NOTIFICATION_API,
} from '../../../constants/api-end-point';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private _http: HttpService) {}

  notificationList(body: any) {
    return this._http.get(NOTIFICATION_API, body);
  }

  sendNotification(body: any) {
    return this._http.post(SEND_NOTIFICATION_API, body);
  }

  resendNotification(body: any) {
    return this._http.post(RESENT_NOTIFICATION_API, body);
  }

  deleteNotification(notificationId: string) {
    return this._http.delete(`${DELETE_NOTIFICATION_API}/${notificationId}`);
  }

  getPushNotification(params) {
    return this._http.get(PUSH_NOTIFICATION_API, params);
  }

  readNotification() {
    return this._http.patch(READ_NOTIFICATION);
  }
  getNotificationCount() {
    return this._http.get(NOTIFICATION_COUNT);
  }
}
