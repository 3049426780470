import {
  ALL_ADS_LIST,
  ALL_DEALS_LIST,
  BLOCKED_USER_LIST,
  COMMUNITY_GUIDELINES,
  COPYRIGHT_POLICY,
  DATA_POLICY,
  DEALS_BANNER,
  DEALS_HOW_IT_WORKS,
  DEALS_TERSM_CONDITIONS,
  DELETED_USER_LIST,
  EXPIRING_SOON_ADS_LIST,
  FAQS,
  MARKETING_CAMPAIGN,
  PRIVACY_POLICY,
  REPORTED_CONTENT_LIST,
  REQUEST_ADS_LIST,
  REQUESTED_DEALS_LIST,
  ROLES_LISTING,
  STATIC_CONTENT_DEALS,
  SUB_ADMIN_LISTING,
  TAGS,
  TERMS_CONDITIONS,
  USER_ADS_HISTORY,
  USER_DEALS_HISTORY,
  BUSINESS_USER_RATINGS_REVIEWS,
  INDIVIDUAL_USER_CHECKIN_HISTORY,
  COMMENT_REPORT,
  CONTENT_REPORT_USER_LIST,
  REPORTED_USER_LIST,
  DEALS_HISTORY_INDIVIDUAL,
  CHECKIN_HISTORY_INDIVIDUAL,
  DELETED_USERS,
} from './routes';

export const CMS_TAB_LINKS = [
  { path: PRIVACY_POLICY.path, label: 'Privacy Policy' },
  { path: TERMS_CONDITIONS.path, label: 'Terms & Conditions' },
  // { path: COMMUNITY_GUIDELINES.path, label: "Community Guidelines" },
  // { path: DATA_POLICY.path, label: "Data Policy" },
  // { path: COPYRIGHT_POLICY.path, label: "Copyright Policy" },
  { path: FAQS.path, label: "FAQ's" },
  { path: TAGS.path, label: 'Tags' },
];

export const MARKETING_CAMPAIGN_TAB_LINKS = [
  { path: ALL_ADS_LIST.path, label: 'All Ads' },
  { path: REQUEST_ADS_LIST.path, label: 'Requested Ads' },
  { path: EXPIRING_SOON_ADS_LIST.path, label: 'Starting-Soon Ads' },
];

export const SUB_ADMIN_TAB_LINKS = [
  { path: ROLES_LISTING.path, label: 'Roles' },
  { path: SUB_ADMIN_LISTING.path, label: 'Sub Admins' },
];

export const DEALS_MANAGEMENT_TAB_LINKS = [
  { path: ALL_DEALS_LIST.path, label: 'All Deals' },
  { path: REQUESTED_DEALS_LIST.path, label: 'Requested Deals' },
  { path: STATIC_CONTENT_DEALS.path, label: 'Static Content' },
];

export const DEALS_MANAGEMENT_STATIC_CONTENT_TAB_LINKS = [
  { path: DEALS_TERSM_CONDITIONS.path, label: 'Terms & Conditions' },
  { path: DEALS_HOW_IT_WORKS.path, label: 'How it Works?' },
  { path: DEALS_BANNER.path, label: 'Banner' },
];

export const USE_DETAILS_TAB_LINKS = [
  { path: USER_ADS_HISTORY.path, label: 'Ads History' },
  { path: USER_DEALS_HISTORY.path, label: 'Deals History' },
  { path: BUSINESS_USER_RATINGS_REVIEWS.path, label: 'Ratings & Reviews' },
];

export const USE_DETAILS_INDIVIDUAL_TAB_LINKS = [
  { path: DEALS_HISTORY_INDIVIDUAL.path, label: 'Deals History' },
  { path: CHECKIN_HISTORY_INDIVIDUAL.path, label: 'Check In History' },
];

export const PAGE_ACCOUNT_TAB_LINKS = [
  { path: REPORTED_CONTENT_LIST.path, label: 'Reported Content' },
  { path: BLOCKED_USER_LIST.path, label: 'Blocked Users' },
  // { path: DELETED_USERS.path, label: 'Deleted Users' },
  { path: DELETED_USER_LIST.path, label: 'Deleted Users' },
  { path: REPORTED_USER_LIST.path, label: 'Reported Users' },
];

export const REPORTED_CONTENT_DETAILS_TAB_LINKS = [
  { path: COMMENT_REPORT.path, label: 'Comment Report' },
  { path: CONTENT_REPORT_USER_LIST.path, label: 'Post Report' },
];

export const CONTENT_TYPES = {
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  TERMS_CONDITIONS: 'TERMS_AND_CONDITIONS',
  COPYRIGHT_POLICY: 'COPYRIGHT_POLICY',
  COMMUNITY_GUIDELINES: 'COMMUNITY_GUIDELINES',
  ABOUT_US: 'ABOUT_US',
  DATA_POLICY: 'DATA_POLICY',
  FAQS: 'FAQ',
  DEAL_TERSMS_AND_CONDITIONS: 'DEAL_TERMS_AND_CONDITIONS',
  DEAL_HOW_IT_WOSRKS: 'DEAL_HOW_IT_WORK',
  DEAL_BANNER: 'DEAL_BANNER',
};

export const CMS_HEADERS = {
  PRIVACY_POLICY: 'Privacy Policy',
  TERMS_CONDITIONS: 'Terms & Conditions',
};

export const CMS_DEALS_HEADERS = {
  TERMS_CONDITIONS: 'Terms & Conditions',
  HOW_IT_wORKS: 'How it Works',
};

export const LANGUAGES_VALUE = {
  ARABIC: 'ar',
  ENGLISH: 'en',
};

export const LANGUAGES = [
  { name: 'Arabic', value: LANGUAGES_VALUE.ARABIC },
  { name: 'English', value: LANGUAGES_VALUE.ENGLISH },
];

export const THEME = {
  DARK: 'dark',
  LIGHT: 'light',
};

export const ONBOARDING_KEYS = {
  ID: 'id',
  TOKEN: 'token',
  DEVICE_TOKEN: 'deviceToken',
  DEVICE_ID: 'deviceId',
  NEW_PASSWORD: 'newPassword',
  ENCRYPTED_TOKEN: 'encryptedToken',
  STRING: 'string',
};

export const STATIC_CONTENT_CONST = {
  DESCRIPTION_EN: 'descriptionEn',
  DESCRIPTION_AR: 'descriptionAr',
  QUESTION_AR: 'questionAr',
  QUESTION_EN: 'questionEn',
  ANSWER_AR: 'answerAr',
  ANSWER_EN: 'answerEn',
  CONTENT_TYPE: 'contentType',
  CREATED: 'created',
  FAQS_ID: 'faqId',
};

export const COMMON_KEYS = {
  LANGUAGE: 'language',
  CMS_PAGE: 'cmsPage',
  THEME: 'theme',
};

export const TABLE_ACTIONS = {
  ACTIVE_BLOCK: 1,
  EDIT: 2,
  DELETE: 3,
  APPROVE_REJECT: 4,
  ADS_STATUS: 5,
};

export const FILE_SIZE = {
  PROFILE_IMAGE_SIZE: 5000 * 1024, // 5 MB
  DOCUMENT_SIZE: 10000 * 1024,
  CATEGORY_IMAGE_SIZE: 10000 * 1024, // 10 MB
};

export const USER_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  BUSINESS: 'BUSINESS',
};

export const MODULE_NAMES = {
  Dashboard: 'dashboard',
  MarketingCampaignManagement: 'Marketing Campaign Management',
  StaticContentManagement: 'Static Content Management ',
  UserManagement: 'User_Management',
  SubAdminManagement: 'Sub-Admin Management',
  DealsManagement: 'Deals-Management',
};

export const TABS = {
  Dashboard: 'dashboard',
  MarketingCampaignManagement: 'Marketing Campaign Management',
  StaticContentManagement: 'Static Content Management ',
  UserManagement: 'User_Management',
  SubAdminManagement: 'Sub-Admin Management',
  DealsManagement: 'Deals-Management',
  DealsStaticContent: 'Deals-static-content',
  UserDetails: 'user-details',
  UserDetailsIndividual: 'user-details-individual',
  PageAccountManagement: 'Page-Account-Management',
  ReportedContentDetails: 'Reported Content Details',
};

export const USER_STATUS = {
  ACTIVE: { name: 'Active', value: 'ACTIVE' },
  IN_ACTIVE: { name: 'Inactive', value: 'INACTIVE' },
  APPROVE: { name: 'Approve', value: 'APPROVE' },
  REJECT: { name: 'Reject', value: 'REJECT' },
  DELETED: {name: 'Deleted' ,value:'DELETED'}
};

export const TOAST_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export enum ADS_STATUS {
  ACTIVE = 'ACTIVE', // ACTIVE === PUBLISH
  PUBLISHED = 'PUBLISHED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  REVOKED = 'REVOKED',
  EXPIRED = 'EXPIRED',
  EXTENDED = 'EXTENDED',
  INPROGRESS = 'INPROGRESS',
  PENDING = 'PENDING',
  DELETED = 'DELETED'
}

const SEACRH_BASE = 'Search By';
export const TABLE_SEACRCH_PLACEHOLDER = {
  USER_MANAGEMENT_SEACRCH: `${SEACRH_BASE} Name, User Name, Phone No.`,
  BUSINESS_MANAGEMENT_SEACRCH: `${SEACRH_BASE} Business Name, Phone Number`,
  TRANSACTION_MANAGEMENT_SEACRCH: `${SEACRH_BASE} Name,Transaction Id, Number`,
  CATEGORY_MANAGEMENT_SEACRCH: `${SEACRH_BASE} Category Name`,
  FAQ_SEARCH_PLACEHOLDER: `${SEACRH_BASE} question`,
  SUB_ADMIN_SEARCH_PLACEHOLDER: `${SEACRH_BASE} Name, Email`,
  ROLE_SEARCH_PLACEHOLDER: `${SEACRH_BASE} Role`,
  NOTIFICATION_SEARCH_PLACEHOLDER: `${SEACRH_BASE} Title`,
  VERSION_SEARCH_PLACEHOLDER: `${SEACRH_BASE} Version`,
  CATALOGUE_SEARCH_PLACEHOLDER: `${SEACRH_BASE} Business Name`,
  PRODUCT_SEARCH_PLACEHOLDER: `${SEACRH_BASE} Product Name`,
  MARKETING_CAMPAIGN_SEARCH: `${SEACRH_BASE} Business Name, Title or Requested by`,
  DEALS_MANAGEMNT_SEARCH: `${SEACRH_BASE} Title, Business Name`,
  DEALS_REDEEMED_USERS_SEARCH: `${SEACRH_BASE} User Name`,
  REPORTED_CONTENT_SEARCH: `${SEACRH_BASE} Posted by`,
  RATINGS_REVIEWS_SEARCH: `${SEACRH_BASE} Business Name`,
  USER_DETAILS_BUSINESS_USER_RATINGS_REVIEWS_SEARCH: `${SEACRH_BASE} User Name`,
  BLOCKED_USER_SEARCH : `${SEACRH_BASE} username, name`,
  COMMENT_REPORT_SEARCH : `${SEACRH_BASE} Commented By`
};

export const ADS_REJECT_REASONS = [
  'Misalignment with Business Goals',
  'Compliance Issues',
  'Poor Track Record',
  'Resource Constraints',
  'Reputation Risks',
  'Duplicate Account',
  'Incomplete or Inaccurate Information',
  'Other',
];
export const reportReasons = [
  'Harassment or Bullying',
  'Hate Speech or Symbols',
  'Threats of Violence or Harm',
  'Spam',
  'Scams or Fraud',
  'Nudity or Sexual Content',
  'False Information',
  'Encouragement of Self-Harm or Suicide',
  'Impersonation',
  'Intellectual Property Violation',
  'Child Exploitation',
  'Other',
];

export enum ADS_TYPE {
  SPONSORED_ADS = 'SPONSORED',
  BOOSTED_ADS = 'BOOSTED',
  NATIVE_ADS = 'NATIVE_ADS',
  MSHA3LIL_ADS = 'MSHA3LIL_ADS',
  ALL_PRICE = 'ALL_PRICE',
}

export enum DEAL_STATUS {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE', // HERE ACTIVE IS APPROVED
  REJECTED = 'REJECTED',
  REVOKED = 'REVOKED',
  INPROGRESS = 'INPROGRESS',
  EXPIRED = 'EXPIRED',
  DELETED = 'DELETED',
  ACCOUNT_DELETED = 'ACCOUNT_DELETED'
}

export enum DEAL_DISCOUNT_TYPE {
  FLAT = 'FLAT',
  PERCENTAGE = 'PERCENTAGE',
}

export const OPERATORS = {
  INCREMENT: 'INCREMENT',
  DECREMENT: 'DECREMENT',
};

export enum LOGGGED_IN_USER_TYPE {
  ADMIN = 'ADMIN',
  SUB_ADMIN = 'SUB_ADMIN',
}

export enum ADS_DEAL_PRICE_ENUM {
  NATIVE_ADS = 'NATIVE_ADS',
  MSHA3LIL_ADS = 'MSHA3LIL_ADS',
  DEAL_PRICE = 'DEAL_PRICE',
  ALL_PRICE = 'ALL_PRICE',
}


export const GENDER = [
  {value:'MALE',label:'Male'},
  {value:'FEMALE',label:'Female'},
  {value:'OTHER',label:'Other'},
]

export const SEND_TO_NOTIFICATION = [
  { value: "4", label: "All" },
  { value: "1", label: "Android Users" },
  { value: "2", label: "iOS Users" },
  { value: "INDIVIDUAL", label: "Individual Users" },
  { value: "BUSINESS", label: "Business Users" },
];


export enum API_TYPE  {
  CONTACT_SUPPORT =  'contact_support'
}

export const REPORTED_CONTENT_TYPE  = {
  POST:{label:'Post',value:'POST'},
  EVENT:{label:'Event',value:'EVENT'},
  CHECK_IN:{label:'Check In',value:'CHECK_IN'},
  SPONSORED_ADS:{label:'Sposored Ad',value:'NATIVE_ADS'}
}