import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../storage/storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  getFcmToken = new BehaviorSubject(null)
  constructor(private _storage: StorageService) {}

  authorization() {
    const token = this._storage.token;
    const auth: any = {
      Authorization: token
        ? 'Bearer ' + token
        : 'Basic ' + btoa(`${'msha3lil'}:${'msha3lil@123'}`),
      Api_key: '' + 1234,
      // deviceType: "" + this._storage.deviceDetail(3),
      // deviceId: "" + this._storage.deviceDetail(1),
      timeZone: '' + this._storage.deviceDetail(4),
      platform: '' + this._storage.deviceDetail(3),
      'accept-language': 'en',
      // credentials:true
      // "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      // 'deviceToken':this.deviceToken ? this.deviceToken :''
    };
    return auth;
  }
}
