const USER_BASE: string = 'api/v1';
const NOTIFICATION_BASE: string = 'notification';
const SUB_ADMIN_BASE: string = 'subadmin';
const ADMIN_BASE: string = 'api/v1/admin';



/**
 * @SERVICES
 */
const USER_SERVICE = 'userService/api/v1';
const USER_TYPE = `${USER_SERVICE}/${ADMIN_BASE}`;

const COLLEGE_SERVICE = 'collegeservice/api/v1';
const COLLEGE_BASE: string = 'college';

const COMMUNITY_SERVICE = 'communityservice/api/v1';
const COMMUNITY_TYPE = `${COMMUNITY_SERVICE}/${ADMIN_BASE}`;

const NOTIFICATION_SERVICE = 'notificationservice/api/v1';
const NOTIFICATION_TYPE = `${NOTIFICATION_SERVICE}/${ADMIN_BASE}`;

const PAYMENT_SERVICE = 'paymentservice/api/v1';
const PAYMENT_TYPE = `${PAYMENT_SERVICE}/${ADMIN_BASE}`;

const COUPON_SERVICE = 'couponservice/api/v1/admin';

/**
 * @CITY_NAME_AND_STATE_NAME
 */
export const CITY_API = `${USER_TYPE}/cities`;
export const STATE_API = `${USER_TYPE}/states`;

/**
 * @ACCOUNT_RELATED_END_POINTS
 */
export const LOGIN_API = `login`;
export const LOGOUT_API = `logout`;
export const FORGOT_PASSWORD_API = `forgot-password`;
export const PROFILE = `profile`;
export const RESET_PASSWORD_API = `reset-password`;
export const CHANGE_PASSWORD_API = `change-password`;
export const VERIFY_OTP_API = `${USER_TYPE}/verify-otp`;
export const USER_INFO_API = `${USER_TYPE}`;
export const ONBOARDING_API = `${USER_TYPE}/profile-complete`;
export const TOKEN_EXPIRY_CHECK = 'verify-token ';
export const EDIT_PROFILE = 'edit-profile';
export const MEDIAL_UPLOAD = 'media-upload'


/**
 * @DASHBOARD_RELATED_END_POINTS
 */
export const DASHBOARD_API = `${USER_TYPE}/dashboard`;
export const DASHBOARD_POST_API = `${COMMUNITY_TYPE}/dashboard`;
export const DASHBOARD_USER_COUNT = `user-count`;
export const DASHBOARD_DEAL_COUNT = `deal-count`;
export const DASHBOARD_ADS_COUNT = `ads-count`;
export const DASHBOARD_CONTENT_COUNT = `content-count`;
export const DASHBOARD_PAGE_ACCOUNT_OVERVIEW = `report-count`
export const DASHBOARD_TOP_20_CHECK_IN = `check-in-count`;
export const DASHBOARD_EXPORT = `export-data`


/**
 * @USERS_RELATED_END_POINTS
 */
export const USER_API = `users`;
export const ADD_USER_API = `${USER_TYPE}/add-user`;
export const USER_LIST_API = `${USER_TYPE}/user`;
export const USER_REPORT_API = `${USER_LIST_API}/report`;
export const USER_EXPORT_API = `${USER_BASE}/export`;
export const MENTOR_REGISTRATION_TOGGLE_API = `${USER_TYPE}/app-config`;
export const RATING_REVIEW_API = `${USER_LIST_API}/reviews`;
export const USER_DETIALS = `user-details`;
export const ADD_DOCUMENTS = `add-documents`;
export const ADS_HISTORY = `ads-history`;
export const BUSINESS_USER_DEALS_HISTORY = `business-deals-history`;
export const INDIVIDUAL_USER_DEALS_HISTORY = `individual-deals-history`;
export const BUSINESS_USER_RATINGS_REVIEWS = `business-review`;
export const INDIVIDUAL_USER_CHECKEDIN_HISTORY = `checked-in-history`


/**
 * @MENTOR_RELATED_END_POINTS
 */
export const GET_MENTOR_API = `${USER_TYPE}/mentor/list`;

/**
 * @BROADCAST_NOTIFICATION_RELATED_END_POINTS
 */
export const NOTIFICATION_API = `notification-list`;
export const SEND_NOTIFICATION_API = `send-notifications`;
export const RESENT_NOTIFICATION_API = `resent-notification`
export const DELETE_NOTIFICATION_API = `notification`;
export const PUSH_NOTIFICATION_API = `admin-notification-list`;
export const READ_NOTIFICATION = `read-notification`;
export const NOTIFICATION_COUNT = `notification-count`

/**
 * @COLLEGE_RELATED_END_POINTS
 */
export const COLLEGE_BRANCH_LIST_API = `${COLLEGE_SERVICE}/${ADMIN_BASE}/branches`;
export const COLLEGE_API = `${COLLEGE_SERVICE}/${ADMIN_BASE}/${COLLEGE_BASE}`;
export const COLLEGE_CSV_API = `${COLLEGE_SERVICE}/${ADMIN_BASE}`;

/**
 * @SUB_ADMIN_RELATED_END_POINTS
 */
export const SUB_ADMINS_API = `${USER_TYPE}/${SUB_ADMIN_BASE}`;
export const ADMIN_LIST_API = `user-list`;
export const ADD_ADMIN_API = `add-subadmin`;
export const EDIT_ADMIN = `edit-subadmin`;
export const ADMIN_BLOCK_UNBLOCK_DELETE_API = `${SUB_ADMINS_API}/status`;
export const SUB_ADMIN_BLOCK_UNBLOCK_API = `${SUB_ADMIN_BASE}/block-unblock`;
export const SUB_ADMIN_ROLE =  `sub-admin-role`;
export const SUB_ADMIN_ROLE_DETAIL = `sub-admin-role-detail`;
export const EDIT_ROLE = `sub-admin-role-permissions`;
export const DELETE_ROLE = `sub-admin-role`;
export const ACTIVE_ROLE_LIST = `sub-admin-active-role`
export const SUB_ADMIN_API = `sub-admin`
export const SUB_ADMIN_DETAIL = `sub-admin-detail`;


/**
 * @CMS_RELATED_END_POINTS
 */
export const STATIC_CONTENT: string = `content`;
export const STATIC_CONTENT_FAQS: string = `faq`;

/**
 * @HOTELS_RELATED_END_POINTS
 */
export const HOTELS:string = `${USER_BASE}/hotel/`;
export const ADD_HOTEL:string = `${USER_BASE}/hotel/save`;
export const HOTEL_DETAILS = `${USER_BASE}/hotel/{hotelId}`;

/**
 * @COMMUNITY_RELATED_END_POINTS
 */
export const COMMUNITY_LIST_API = `${COMMUNITY_TYPE}/communities`;
export const COMMUNITY_IMPORT_CSV_API = `${COMMUNITY_TYPE}/upload-community-data`;
export const COMMUNITY_DETAIL_API = (id: any) => `${COMMUNITY_LIST_API}/${id}`;

/**
 * @REPORTS_RELATED_END_POINTS
 */
export const REPORT_LIST_API = `${COMMUNITY_TYPE}/posts`;
export const REPORT_ACTIVE_INACTIVE_API = `${COMMUNITY_TYPE}/enableDisablePost`;

/**
 * @MENTOR_LMS_RELATED_END_POINTS
 */
export const MENTOR_LMS_LIST_API = `${USER_TYPE}/mentor/lms`;
export const MENTOR_LMS_DETAIL_API = (id: any) =>
  `${USER_TYPE}/mentor/lms/${id}`;
export const MENTOR_LMS_STATUS_API = `${USER_TYPE}/mentor/lms/status`;
export const UPDATE_MENTOR_LMS_API = `${USER_TYPE}/mentor/lms/edit`;

/**
 * @REFERRAL_MANAGEMENT_RELATED_END_POINTS
 */
export const REFERRAL_LIST_API = `${USER_TYPE}/referral`;
export const COUPON_LIST_API = `${COUPON_SERVICE}/usercoupon`;
export const TOTAL_COUPON_LIST_API = `${COUPON_SERVICE}/referalDetails`;

/**
 * @CALL_CHAT_MANAGEMENT_RELATED_END_POINTS
 */
export const CALL_CHAT_LIST_API = `${USER_TYPE}/bookings/list`;
export const CALL_SUBTITLE_API = `${USER_SERVICE}/bookings/booking-callDetails`;
export const CALL_CHAT_DETAIL_API = (id: any) => `${CALL_CHAT_LIST_API}/${id}`;

/**
 * @TRANSACTION_MANAGEMENT_RELATED_END_POINTS
 */
export const TRANSACTION_MANAGEMENT_LIST_API = `${PAYMENT_TYPE}/transactions`;
export const TRANSACTION_MANAGEMENT_DETAIL_API = (id: any) =>
  `${TRANSACTION_MANAGEMENT_LIST_API}/${id}`;

/**
 * @AGORA_RELATED_END_POINTS
 */
export const AGORA_TOKEN = `${USER_TYPE}/agora/login`;



/**
 * @MARKET_CAMPAIGN_MANAGEMENT_RELATED_END_POINTS
*/

export const ADS_LISTING = `get-ads`;
export const UPDATE_ADS_STATUS = `update-ads-status`;
export const ADS_DETAILS = `ads-detail`;
export const ADS_PRICE = `ads-price`;


/**
 * @CATEGORY_RELATED_END_POINTS
*/

export const CATEGORY = `category`;
export const CATEGORY_LIST = `category-list`;
export const CATEGORY_DETAIL = `category-detail`;
export const SUB_CATEGORY = `sub-category`


/**
 * @DEALS_RELATED_END_POINTS
*/

export const DEALS = `deals`;
export const DEAL_DETAIL = `deal-detail`;
export const DEAL_STATUS = `deal-status`;
export const DEAL_USERS = `deal-users`


/**
 * RATINGS_REVIEWS_RELATED_END_POINTS
*/

export const RATINGS_RAVIEWS_LIST = `user-rating`;
export const DELETE_REVIEW = `user-review/`


/**
 * PAGE_ACCOUNT_MANAGEMENT_RELATED_END_POINTS
*/

export const REPORTED_CONTENT_LIST = `reported-content`
export const REPORTED_CONTENT_DETAIL = `reported-content-detail`
export const REPORTED_COMMENT_LIST = `reported-comment`;
export const CONTENT_REPORT_USER_LIST = `reported-content-user`;
export const DELETE_REPORTED_CONTENT = `reported-content` ;
export const DELETE_REPORTED_COMMENT = `reported-comment`;
export const BLOCKED_USER_LIST = `blocked-users/list`;
export const DELETED_USER_LIST = `deleted-users/list`;
export const REPORTED_USER_LIST = `reported-users/list`


/**
 * @CONTACT_SUPPORT_RELATED_END_POINTS
 */

export const CONTACT_SUPPORT = `contact-support`