import { Injectable, InjectionToken, Inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError, finalize, timeout } from 'rxjs/operators';
import { LoaderService } from 'src/app/components/loader/loader.service';
import { ToastService } from 'src/app/components/toast-notification/toast.service';
import { StorageService } from '../storage/storage.service';
import { CryptoService } from '../crypto/crypto.service';
import { AuthService } from './auth/auth.service';
import { retryWithBackOff } from 'src/app/constants/retryApiWithBackOff';
import { ACCOUNT } from 'src/app/constants/routes';
import { INTERNAL_SERVER_ERROR, NO_INTERNET } from 'src/app/constants/messages';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {
  constructor(
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
    private _auth: AuthService,
    private _toast: ToastService,
    private _ls: LoaderService,
    private _store: StorageService,
    private _router: Router,
    private _crypto: CryptoService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> | any {
    const timeoutValue =
      Number(req.headers.get('timeout')) || this.defaultTimeout;

      let httpHeader = this._auth.authorization();

      if(req.headers.has('removeBearerToken')){   // adding Basic  auth for the API's which requrest has 'reremoveBearerToken' header 
        httpHeader.Authorization =  'Basic ' + btoa(`${'msha3lil'}:${'msha3lil@123'}`);
        req = req.clone({ headers: req.headers.delete('removeBearerToken') });
      }
     
      let authReq = req.clone({ setHeaders: httpHeader });


    return next.handle(authReq).pipe(
      timeout(3*60*1000),
      map((event: HttpEvent<any>): any => {
        if (event instanceof HttpResponse) {
          this._ls.hide();
          return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 403) {
          localStorage.clear();
          this._router.navigate([ACCOUNT.path]);
          this.errorState(error);
        }
        if (error.error && error.error.data) {
          let decipher = this._crypto.decryptValue(error.error.data);
          let formatData = { body: { data: JSON.parse(decipher.toString()) } };
          this.errorState(error, formatData.body.data);
          return throwError(formatData.body.data);
        } else {
          this.errorState(error);
          return throwError(error.error);
        }
      }),
      finalize(() => {
        this._ls.hide();
      })
    );
  }

  errorState(error: any, data?: any) {
    this._ls.hide();
    if (error.status == 0) {
      if (!navigator.onLine) {
        this._toast.error(NO_INTERNET);
      } else {
        this._toast.error(INTERNAL_SERVER_ERROR);
      }
    } else if (error.status == 401) {
      this._toast.error(data ? data.message : error.error.message);
      this._store.logout();
    } else if (error.status == 404) {
      this._toast.error(data ? data.message : error.error.message);
      this._router.navigate(['404']);
    } else {
      this._toast.error(data ? data.message : error.error.message);
    }
  }
}
