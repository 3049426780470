import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { ToastNotificationComponent } from './components/toast-notification/toast-notification.component';
import { LoaderComponent } from './components/loader/loader.component';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { environment } from 'src/environments/environment';
import { getMessaging, getToken, Messaging } from 'firebase/messaging';
import { AuthService } from './services/interceptor/auth/auth.service';
import { StorageService } from './services/storage/storage.service';

const app = initializeApp(environment.firebaseConfig);
// const analytics = getAnalytics(app);
// const messaging = getMessaging(app);

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    LoaderComponent,
    ToastNotificationComponent,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'angular-RCC';

  constructor(
    private _authService: AuthService,
    private _storageService: StorageService
  ) {}
  ngOnInit(): void {
    this._getDeviceToken();
  }

  async _getDeviceToken() {
    const messaging = getMessaging(app);
    if (Notification.permission == 'granted') {
      try {
        const fcmToken = await getToken(messaging, {
          vapidKey: environment.firebaseConfig.vapidKey,
        });
        if (fcmToken) {
          this._storageService.setFcmToken(fcmToken);
          console.log('fcm-Token==>', this._storageService.fcmToken);
        }
      } catch (err) {
        console.log('err In getting fcm token==>', err);
      }
    } else {
      console.log('Notification Permission==>', Notification.permission);
    }
  }
}
